<template>
  <div>
    <!-- <van-field v-model="dataForm.school_name" name="schoolName" :rules="rules.SchoolName" type="text" class="form-item" :placeholder="$t('baseInfo.schoolName')" />
    <van-field v-model="dataForm.professional_name" name="professionalName" :rules="rules.ProfessionalName" type="text" class="form-item" :placeholder="$t('baseInfo.professionalName')" />
    <datetime-picker v-model="dataForm.school_hours" :max-date="inMaxDate" :min-date="inMinDate" placeholder="baseInfo.schoolHours" :is-focus-point.sync="isFocusPoint" />
    <datetime-picker v-model="dataForm.graduation_time" :max-date="outMaxDate" :min-date="outMinDate" placeholder="baseInfo.graduationTime" :is-focus-point.sync="isFocusPoint" /> -->
  </div>
</template>

<script>
export default {
  name: 'Student',
  data() {
    return {
      submitFlag: true,
      paydayList: [],
      inMinDate: new Date(2010, 0, 1), // 入学最小时间
      inMaxDate: new Date(), // 入学最大时间
      outMinDate: new Date(),
      outMaxDate: new Date(2030, 11, 31),
      dataForm: {
        // school_name: '', // 学校名称
        // professional_name: '', // 专业名称
        // school_hours: '', // 上学时间
        // graduation_time: '' // 毕业时间
      }
    }
  },
  mounted() {
    this.$emit('submitFlag', true)
  },
  methods: {
    submit() {

    }
  }
}
</script>
<style lang="scss" scoped>

</style>
